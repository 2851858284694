import { useEffect, useState } from "react";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

export function useDequery<D, E>(options: UseQueryOptions<D, E>) {
  const { data, ...query } = useQuery(options);
  const [cache, setCache] = useState<D>();
  useEffect(() => {
    if (data) setCache(data);
  }, [data]);

  return { data: cache, ...query } as UseQueryResult<D, E>;
}
