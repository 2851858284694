import { ReactNode } from "react";
import { variantClasses } from "utility/variant-classes";
import { BroadVariant } from "utility/variant-classes/interfaces";

import { StyleProps } from "../interfaces";

interface LabeledLineProps extends StyleProps {
  children: ReactNode;
  variant?: BroadVariant;
}

export function LabeledLine({
  children,
  variant = "disabled",
  ...props
}: LabeledLineProps) {
  const lineClasses = variantClasses(
    {
      primary: "bg-primary text-white",
      success: "bg-success text-white",
      secondary: "bg-secondary text-white",
      warning: "bg-warning text-white",
      danger: "bg-danger text-white",
      white: "bg-white text-primary",
      blue: "bg-blue text-white",
      disabled: "bg-charcoal-light text-charcoal-accent",
      default: "flex-1 h-px",
    },
    { variant }
  );

  return (
    <div {...props}>
      <div className="flex items-center gap-4">
        <div className={lineClasses}></div>
        <div>{children}</div>
        <div className={lineClasses}></div>
      </div>
    </div>
  );
}
