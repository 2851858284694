import { useQueryString } from "hooks/query-string";
import Link from "next/link";

import { QueryLinkProps } from "./interfaces";

export function QueryLink({
  query,
  children,
  href,
  disabled,
  ...props
}: QueryLinkProps) {
  const { copyTo } = useQueryString(query);

  return disabled ? (
    <div className="cursor-not-allowed">{children}</div>
  ) : (
    <Link className="flex" href={copyTo(href).toString()} {...props}>
      {children}
    </Link>
  );
}
