import { LinkedInIcon } from "assets/icons";
import { SolidButton } from "components/atomic";
import { useTranslation } from "hooks/translation";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { BroadVariant } from "utility/variant-classes/interfaces";

interface LinkedInButtonProps {
  action: "job-seeker" | "employer" | "sign-in";
  variant?: BroadVariant;
  children?: ReactNode;
}

const validOrigins =
  process.env.NODE_ENV === "development"
    ? [
        "http://localhost:3000",
        "https://perfectlyhired.com",
        "https://dev.perfectlyhired.com",
        "https://qa.perfectlyhired.com",
      ]
    : [
        "https://perfectlyhired.com",
        "https://dev.perfectlyhired.com",
        "https://qa.perfectlyhired.com",
      ];

export function LinkedInButton({
  action,
  variant = "blue",
  children,
}: LinkedInButtonProps) {
  const { $t } = useTranslation();
  const router = useRouter();

  const redirectToAuth = () => {
    const redirectUrl = (router.query.next as string) || "";

    if (redirectUrl) {
      localStorage.setItem("linkedin-redirect", redirectUrl);
    }

    if (
      redirectUrl.includes("/jobs/application") ||
      redirectUrl.includes("/test/shared-link")
    ) {
      action = "job-seeker";
    }

    const origin = validOrigins.includes(window.location.origin)
      ? window.location.origin
      : validOrigins[0];
    const returnUrl = `${origin}/auth/linkedin/${action}`;
    const oauthParams = new URLSearchParams({
      response_type: "code",
      client_id: process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID,
      redirect_uri: returnUrl,
      scope: "r_liteprofile r_emailaddress",
    });

    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?${oauthParams.toString()}`;
  };

  return (
    <SolidButton
      onClick={redirectToAuth}
      variant={variant}
      leftIcon={LinkedInIcon}
    >
      {children ||
        $t(`account:linkedin.${action === "sign-in" ? "sign-in" : "sign-up"}`)}
    </SolidButton>
  );
}
