import { useEffect, useRef, useState } from "react";

export function useDebounce<T>(value: T, delay = 500): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
}

export function useDebounceSetter<T>(
  value: T,
  setter: (value: T) => void,
  delay = 500,
  clear: T | false = false
) {
  useEffect(() => {
    const handler = setTimeout(() => {
      setter(clear === false ? value : clear);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value, setter, clear]);
}

export function useDebouceUseEffect(
  effect: () => void,
  dependencies: any[],
  delay = 1000
) {
  const timeoutRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      effect();
    }, delay);
  }, dependencies);
}
