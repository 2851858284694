import { useDebounceSetter } from "hooks/debounce";
import {
  useQueryState as useOriginalQueryState,
  UseQueryStateOptions,
} from "next-usequerystate";
import { useCallback, useState } from "react";
import { Setter } from "utility/object-state";

import { QuerySetter, QuerySetterValue } from "./interfaces";

export function useQueryState<Type = string>(
  name: string,
  type?: UseQueryStateOptions<Type> & { defaultValue: Type },
  debounce = 0,
): [Type, QuerySetter<Type>] {
  const [value, setter] = useOriginalQueryState<Type>(name, type);

  return [
    value,
    useCallback(
      async (newValue: QuerySetterValue<Type>) => {
        await setter(newValue, { scroll: false, shallow: true });
        return true;
      },
      [setter],
    ),
  ];
}

export function useDebouncedQueryState<Type = string>(
  name: string,
  type?: UseQueryStateOptions<Type> & { defaultValue: Type },
  delay = 500,
  reset = "_",
): [Type, Setter<Type>] {
  const [query, setQuery] = useOriginalQueryState<Type>(name, type);
  const [state, setState] = useState(query);

  const shouldReset = query === reset;
  useDebounceSetter(
    state,
    useCallback(
      (newValue: QuerySetterValue<Type>) =>
        setQuery(newValue, { scroll: false, shallow: true }),
      [setQuery],
    ),
    delay,
    shouldReset && type.defaultValue,
  );

  if (shouldReset && state !== type.defaultValue) {
    setState(type.defaultValue);
  }

  return [state, setState];
}
