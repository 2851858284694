import classNames from "classnames";
import { QueryLink } from "components/atomic/query-link";
import Link from "next/link";
import { ReactNode } from "react";
import { StyleProps } from "../../atomic/interfaces";

interface AlternativeLink {
  question?: ReactNode;
  text: ReactNode;
  url?: string;
}

interface TwoPanesProps extends StyleProps {
  heading?: ReactNode;
  pitches?: ReactNode[];
  alternatives?: AlternativeLink[];
  children?: ReactNode;
}

function Alternatives({
  alternatives,
  className,
}: {
  alternatives: AlternativeLink[];
  className?: string;
}) {
  return (
    <>
      {alternatives.map((alternative, index) => (
        <p key={index} {...(className ? { className } : {})}>
          {alternative.question && <span>{alternative.question} </span>}
          {alternative.url ? (
            <QueryLink href={alternative.url}>{alternative.text}</QueryLink>
          ) : (
            alternative.text
          )}
        </p>
      ))}
    </>
  );
}

export function TwoPanes({
  heading,
  pitches,
  alternatives,
  children,
}: TwoPanesProps) {
  return (
    <div className="flex flex-col items-stretch gap-x-15 gap-y-4 pb-8 lg:flex-row lg:gap-y-8 xl:gap-x-60">
      {(heading || pitches?.length > 0 || alternatives?.length > 0) && (
        <header className="lg:max-w-md">
          {heading && (
            <h1
              className={classNames(
                "font-poppins font-bold",
                "text-xl md:text-3xl lg:text-7xl",
                "leading-[34px] md:leading-[48px] lg:leading-[96px]",
                "mt-4 lg:mt-0"
              )}
            >
              {heading}
            </h1>
          )}
          {pitches?.length > 0 && (
            <section className="mt-2 text-base leading-[28px] lg:mt-5 lg:text-2xl lg:leading-[43px]">
              {pitches.map((pitch, index) => (
                <p key={index}>{pitch}</p>
              ))}
            </section>
          )}
          {alternatives?.length > 0 && (
            <section className="hidden space-y-[20px] pt-[50px] text-sm lg:block">
              <Alternatives alternatives={alternatives} />
            </section>
          )}
        </header>
      )}
      <div className="flex-1 space-y-[30px] lg:max-w-lg">
        <article>{children}</article>
        {alternatives?.length > 0 && (
          <section className="max-w-[240px] space-y-[10px] text-sm sm:max-w-none lg:hidden">
            <Alternatives
              alternatives={alternatives}
              className="flex flex-wrap gap-x-4"
            />
          </section>
        )}
      </div>
    </div>
  );
}
