import { useCallback } from "react";
import { context } from "utility/context";
import { QueryProp } from "./interfaces";

export function useQueryString(query?: QueryProp) {
  query =
    query ?? "copy" === "copy"
      ? context.location?.href
        ? Object.fromEntries(new URL(context.location?.href).searchParams)
        : {}
      : query || {};

  return {
    query,
    copyTo: useCallback(
      <T extends string | URL>(href: T) => {
        if (typeof window === "undefined") return href;

        const backUrl = new URL(
          href,
          `${window.location.protocol}//${window.location.host}`
        );

        backUrl.search = new URLSearchParams(
          query as Record<string, string>
        ).toString();

        return typeof href === "string" ? backUrl.toString() : backUrl;
      },
      [query]
    ),
  };
}
