// VALIDITY FUNCTIONS

import { EMAIL_REGEX, PASSWORD_REGEX } from "utility/regex";

export const addValidProps = (value: boolean) => {
  return {
    error: !value,
    success: value,
  };
};
export const addValidPropsWithMessage = ({
  condition,
  errorMessage,
  showOnError = false,
}: {
  condition: boolean;
  showOnError?: boolean;
  errorMessage: string | string[];
}) => {
  return {
    error: !condition,
    success: showOnError ? undefined : condition,
    message: !condition && errorMessage,
  };
};

export const validateEmail = (email: string) => {
  return EMAIL_REGEX.test(email.toLowerCase());
};

export const validatePassword = (password: string) => {
  return PASSWORD_REGEX.test(password);
};

export const validate = (
  regex: RegExp,
  value: string,
  condition: boolean = true
) => {
  const isValid = regex.test(value) && condition;
  return {
    error: !isValid,
    success: isValid,
  };
};
